export const myCustomSort = (data, colIndex, order, cols) => {
    let dateCols = cols.reduce(
      (dateCols, currCol, index) => {
        if (
          currCol.name.toLowerCase().indexOf('date') !==
          -1
        ) {
          return [...dateCols, index];
        } else return dateCols;
      },
      []
    );
    let amountCols = cols.reduce(
      (amountCols, currCol, index) => {
        if (
          currCol.name
            .toLowerCase()
            .indexOf('amount') !== -1 || currCol.name
            .toLowerCase()
            .indexOf('number') !== -1
        ) {
          return [...amountCols, index];
        } else return amountCols;
      },
      []
    );
    return data.sort((a, b) => {
      if (dateCols.indexOf(colIndex) !== -1) {
        return (
          (new Date(a.data[colIndex]) <
          new Date(b.data[colIndex])
            ? -1
            : 1) * (order === 'desc' ? 1 : -1)
        );
      } else if (amountCols.indexOf(colIndex) !== -1) {
        return (
          (parseFloat(
            a.data[colIndex].toString().replace('$', '').replaceAll(',','')
          ) <
          parseFloat(
            b.data[colIndex].toString().replace('$', '').replaceAll(',','')
          )
            ? -1
            : 1) * (order === 'desc' ? 1 : -1)
        );
      } else {
        return (
          (a.data[colIndex] < b.data[colIndex]
            ? -1
            : 1) * (order === 'desc' ? 1 : -1)
        );
      }
    });
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const checkComplexEntry = (entry)  => (typeof entry  === 'object') ?
    entry.props.children[0].props.children.toLowerCase() : entry.toString().toLowerCase();

export const customTableSort = (data, colIndex, order, priceColumn = undefined) => {
  if (priceColumn && colIndex === priceColumn) {
    return data.sort((a, b) => {
      const firstData = parseInt(a.data[colIndex].slice(1).replaceAll(',', ''));
      const secondData = parseInt(b.data[colIndex].slice(1).replaceAll(',', ''));
      return (firstData === secondData ? 0 : ((firstData < secondData) ? -1 : 1)) * (order === 'asc' ? 1 : -1)
    })
  }
  return data.sort((a, b) => {
    const firstData = (typeof a.data[colIndex] === 'object'
        ? a.data[colIndex].props.children.toString().toLowerCase() : a.data[colIndex].toString().toLowerCase());
    const secondData = (typeof b.data[colIndex] === 'object'
        ? b.data[colIndex].props.children.toString().toLowerCase() : b.data[colIndex].toString().toLowerCase());
    return (firstData === secondData ? 0 : ((firstData < secondData) ? -1 : 1)) * (order === 'asc' ? 1 : -1)
  })
}

import React from 'react'
import { TableCell } from '@material-ui/core'
import { currencyFormat, noWrapFormat, dateFormat } from '../utils/formatter'

const columnStyleWithWidth = {
  top: '0px',
  left: '0px',
  zIndex: '100',
  position: 'sticky',
  backgroundColor: '#fff',
  width: '800px'
}

export const actransCols = [
  {
    name: 'FISCAL_YEAR',
    label: 'Year',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'PERIOD',
    label: 'Prd',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'COMPANY',
    label: 'Co',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACTIVITY',
    label: 'Activity',
    options: {
        customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },

  {
    name: 'ACTIVITY_GRP',
    label: 'Activity Group',
    options: {
        customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },

  {
    name: 'ACCT_CATEGORY',
    label: 'Account Cat',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_DATE',
    label: 'Tran Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'POSTING_DATE',
    label: 'Posting Date',
    options: {
      customBodyRender: dateFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCT_UNIT',
    label: 'Acct Unit',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'ACCOUNT',
    label: 'Account',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SUB_ACCOUNT',
    label: 'Sub Acct',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'DESCRIPTION',
    label: 'Desc',
    options: {
      customBodyRender: noWrapFormat,
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_AMOUNT',
    label: 'Amt',
    options: {
      customBodyRender: currencyFormat,
      setCellProps: value => {
        return {
          style: { textAlign: 'right' }
        }
      },
      filter: true,
      sort: true
    }
  },
  {
    name: 'MORE',
    label: 'More',
    options: {
      filter: false,
      sort: false,
      download: false
    }
  }
]

export const actransMoreCols = [
  {
    name: 'FROM_COMPANY',
    label: 'From Comp',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'UNITS_AMOUNT',
    label: 'Unit Amt',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRAN_CURRENCY',
    label: 'Currency',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SOURCE_CODE',
    label: 'Source Code',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'OPERATOR',
    label: 'Operation',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'CONTROL_GROUP',
    label: 'Control Group',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'TRANS_TYPE',
    label: 'Type',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'STATUS',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'SYSTEM',
    label: 'System',
    options: {
      filter: true,
      sort: true
    }
  }
]
